import { Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';
import { BasketProductToCompare, OrderItem, Product } from '../../../interfaces/product.interface';
import { Option, OrderItemModifier } from '../../../interfaces/option.interface';
import { OptionGroup } from '../../../interfaces/option-group.interface';
import { Order } from 'src/interfaces/order.interface';
import { Browser } from '@capacitor/browser';
import { GlobalStateModel } from '../../../store/state.model';
import { Store } from '@ngxs/store';
import { NavigationService } from '@modules/navigation/services';
import { ToastService } from '../../../services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(
    private router: Router,
    private serializer: UrlSerializer,
    private navigation: NavigationService,
    private store: Store,
    private toast: ToastService
  ) {}

  private get _window(): Window {
    return window;
  }

  get isBrowser(): boolean {
    return Capacitor.getPlatform() === 'web';
  }

  get win(): Window {
    return this._window;
  }

  get nativeWindow(): Window {
    return this._window;
  }

  openBlank(url) {
    this.win.open(url, '_blank');
  }

  redirectTo(url) {
    this.win.location.href = url;
  }

  getMyHost() {
    return this.win.location.protocol + '//' + this.win.location.host;
  }

  getQueryStringParam(paramName) {
    const url = this.win.location.href;
    let paramValue;
    const split = url.split('?');
    if (split[1]) {
      const httpParams = new HttpParams({ fromString: split[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  queryStringify(params: any) {
    const tree = this.router.createUrlTree(['/'], { queryParams: params });
    return this.serializer.serialize(tree).substr(2);
  }

  toOrderItem(prod: Product, selectedOptions: Option[], quantity: number, orderItemID: string, recipientName?: string): OrderItem {
    return {
      categoryID: prod.categoryID,
      guestName: recipientName,
      instructions: null,
      longDesc: prod.longDesc,
      menuID: prod.menuID,
      name: prod.name,
      options: selectedOptions.map(op => this.toOrderItemModifier(op)),
      orderItemID,
      productID: prod.productID,
      quantity: quantity < prod.minQuantity ? prod.minQuantity : quantity,
      shortDesc: prod.shortDesc,
      standardImageURL: prod.standardImageURL,
      thumbnailImageURL: prod.thumbnailImageURL,
      totalCents: prod.priceCents,
      userID: null,
      isAlcohol: prod.isAlcohol,
      nameSlug: prod.nameSlug,
      seoDescription: prod.seoDescription,
    } as OrderItem;
  }

  checkIfModificationRequired(optionGroup: OptionGroup): boolean {
    if ((optionGroup.minRequired > 0 || optionGroup.mandatorySelection) && !optionGroup.options.some(op => op.isDefault)) {
      return true;
    }
    // tslint:disable-next-line:max-line-length
    // if (optionGroup.options && optionGroup.options.length > 0 && optionGroup.options.some(op => (op.optionGroups && op.optionGroups.length > 0))) {
    //   // tslint:disable-next-line:max-line-length
    // tslint:disable-next-line:max-line-length
    //   return optionGroup.options.some(op => op.optionGroups && op.optionGroups.length && op.optionGroups.some(og => this.checkIfModificationRequired(og)));
    // } else {
    //   return (optionGroup.minRequired > 0 || optionGroup.mandatorySelection) && !optionGroup.options.some(op => op.isDefault);
    // }
  }

  checkForDuplicateProduct(order: Order, product: OrderItem, menuProduct: Product): OrderItem {
    const defaults = this.getDefaultModifiers(menuProduct.optionGroups);
    const currentOptions = product.options.map(p => p.optionID);
    const combinedOptions = currentOptions.concat(defaults);
    const productToAdd: BasketProductToCompare = {
      id: product.productID,
      options: [...new Set(combinedOptions)],
    };
    // tslint:disable-next-line:max-line-length
    const duplicateCandidate = order.items.find(
      i => JSON.stringify(this.convertBasketItemToIdentifyDuplicate(i)) === JSON.stringify(productToAdd)
    );
    if (duplicateCandidate && product && duplicateCandidate?.guestName && product?.guestName) {
      if (duplicateCandidate?.guestName !== product?.guestName) {
        return null;
      } else {
        return duplicateCandidate;
      }
    } else {
      return null;
    }
  }

  getDefaultModifiers(optionGroups: OptionGroup[]): string[] {
    // Initialize a map to track seen option IDs.
    const seenIds = new Map();

    // Use a stack to manage the processing of option groups.
    // Initially, it's populated with the given option groups.
    const stack = [...optionGroups];

    // Process each group on the stack until there are none left.
    while (stack.length > 0) {
      // Pop the last group off the stack for processing.
      const currentGroup = stack.pop();

      // Check if the current group has options to process.
      if (currentGroup && currentGroup.options) {
        for (const option of currentGroup.options) {
          // If the option is marked as default or inverted, add its ID to the seenIds map.
          if (option.isDefault || option.isInverted) {
            seenIds.set(option.optionID, true);
          }

          // If the option is default and has nested option groups,
          // add these groups to the stack for further processing.
          if (option.isDefault && option.optionGroups) {
            stack.push(...option.optionGroups);
          }
        }
      }
    }

    // Convert the map keys (unique option IDs) to an array and return.
    return Array.from(seenIds.keys());
  }

  // recursiveModifiers(optionGroup: OptionGroup): string[] {
  //   const seenIds = new Map();
  //   const stack = [optionGroup];
  //
  //   while (stack.length > 0) {
  //     const currentGroup = stack.pop();
  //     if (currentGroup && currentGroup.options) {
  //       for (const option of currentGroup.options) {
  //         if (option.isDefault || option.isInverted) {
  //           seenIds.set(option.optionID, true);
  //         }
  //         if (option.isDefault && option.optionGroups) {
  //           stack.push(...option.optionGroups);
  //         }
  //       }
  //     }
  //   }
  //
  //   return Array.from(seenIds.keys());
  // }

  convertBasketItemToIdentifyDuplicate(item: OrderItem): BasketProductToCompare {
    return {
      id: item.productID,
      options: item.options.map(p => p.optionID),
    };
  }

  toSlug(name: string) {
    return name
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 \-]/g, '')
      .replace(/ +/g, '-');
  }

  scrollToCenter(id: string) {
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 500);
  }

  parseOutQueryParamsFromInternalLink(url: string) {
    // return an object of the query params and their values as well as the url without the query params
    const urlParts = url.split('?');
    const queryParams = {};
    if (urlParts.length > 1) {
      const params = urlParts[1].split('&');
      params.forEach(param => {
        const [key, value] = param.split('=');
        queryParams[key] = value;
      });
    }
    return { url: urlParts[0], queryParams };
  }

  base64DecodeXOR(encodedStr, key) {
    // Base64 decode
    const decodedStr = atob(encodedStr);

    // XOR decode with the key
    const xorKey = key.toString();
    let result = '';

    for (let i = 0; i < decodedStr.length; i++) {
      const decodedChar = decodedStr.charCodeAt(i);
      const keyChar = xorKey.charCodeAt(i % xorKey.length);
      const xorChar = decodedChar ^ keyChar; // XOR operation
      result += String.fromCharCode(xorChar);
    }
    return result;
  }

  base64EncodeXOR(str: string, key: number): string {
    const xorKey = key.toString();
    let result = '';

    for (let i = 0; i < str.length; i++) {
      const charCode = str.charCodeAt(i);
      const keyCharCode = xorKey.charCodeAt(i % xorKey.length);
      const xorCharCode = charCode ^ keyCharCode; // XOR operation
      result += String.fromCharCode(xorCharCode);
    }

    // Base64 encode the result
    return btoa(result);
  }

  handleDynamicLink(link: string) {
    if (link.includes('http')) {
      Browser.open({
        url: link,
        windowName: '_self',
      });
    } else {
      const { url, queryParams } = this.parseOutQueryParamsFromInternalLink(link);
      if (queryParams && queryParams['coupon']) {
        const key = 8675309;
        const decodedXorStr = this.base64DecodeXOR(queryParams['coupon'], key);
        sessionStorage.setItem('coupon', decodedXorStr);
      }
      if (queryParams && queryParams['reward']) {
        sessionStorage.setItem('reward', queryParams['reward']);
      }
      if (queryParams && queryParams['product']) {
        sessionStorage.setItem('product', queryParams['product']);
        if (this.store.selectSnapshot((state: GlobalStateModel) => state.order.order)) {
          this.navigation.navigateToMenuPage(this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location.locationID);
          return;
        } else {
          this.toast.info('Please select a location first');
        }
      }
      if (queryParams && queryParams['category']) {
        if (this.store.selectSnapshot((state: GlobalStateModel) => state.order.order)) {
          this.navigation.navigateToMenuPage(
            this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location.locationID,
            queryParams['category']
          );
          return;
        } else {
          this.toast.info('Please select a location first');
          sessionStorage.setItem('category', queryParams['category']);
        }
      }
      this.router.navigate([url], { queryParams });
    }
  }

  isValidURL(url: string): boolean {
    try {
      new URL(url); // Try to create a URL object
      return true; // If no error is thrown, the URL is valid
    } catch (error) {
      return false; // If an error is thrown, the URL is invalid
    }
  }

  private toOrderItemModifier(op: Option): OrderItemModifier {
    return {
      name: op.name,
      addedCents: op.addedCents,
      nutritionInfo: op.nutritionInfo,
      optionID: op.optionID,
      quantity: 1,
      modifierCategoryID: op.modifierCategoryID,
      optionGroupID: op.parentOptionGroupID,
    } as OrderItemModifier;
  }
}
